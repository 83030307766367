'use strict';

function disableInputDiagrams(element, formObject) {
	$(element).addClass('d-none');
	$(element).find(':input').each(function () {
    	$(this).attr('disabled', 'disabled');
    });
}

function enableInputDiagrams(element, formObject) {
	$(element).removeClass('d-none');
	$(element).find(':input').each(function () {
    	$(this).removeAttr('disabled');
    });
}


module.exports = {
    radioClick: function () {
        $('.corner-size-shape .corner-radio').on('click', function () {
            $(this).parents().siblings('.corner-size-shape').find('.measurement').attr('disabled', 'disabled').val('');
            $(this).siblings('div.measurements').find($('input.measurement')).removeAttr('disabled');
        });
    },
    sideClick: function () {
        $('.side-link').on('click', function () {
            var side = $(this).text();
            $('.modal-title .side').html(side);
            $('.modal-measurement').each(function () {
                var targetInput = $(this).data('unit') + '-' + side;
                $(this).data('target', targetInput);
                var targetValue = $('#' + targetInput).val();
                $(this).val(targetValue ? targetValue : '');
            });
        });
    },
    modalSubmit: function () {
        $('.modal-submit').on('click', function () {
            var input = true;
            $('.modal-measurement').each(function () {
                var measurement = $(this).val();
                var targetInput = $(this).data('target');
                $('#' + targetInput).val(measurement);
                $(this).val('');
                if (!measurement) {
                    input = false;
                }
            });
            var targetSpan = '#side-' + $('.modal-title .side').text() + ' .side-link';
            $(targetSpan)
                .css('opacity', input ? '1' : '0.7')
                .removeClass(input ? 'btn-primary' : 'btn-success')
                .addClass(input ? 'btn-success' : 'btn-primary');
        });
    },
    modalClose: function () {
        $('.modal-header .close').on('click', function () {
            $('.modal-measurement').each(function () {
                $(this).val('');
            });
        });
    },
    stepsSelect: function () {
        $('input[type=radio].toggle-form').on('click', function () {
            var activate = $(this).val();
            $(this)
                .parents('.col-12')
                .siblings()
                .find(':input')
                .toggleClass('required')
                .prop('disabled', function (index, value) {
                    if (activate === 'Yes') {
                        value = false;
                    } else {
                        value = true;
                    }
                    return value;
                })
                .prop('required', function (index, value) {
                    if (activate === 'Yes' && !$(this).data('default')) {
                        value = true;
                    } else {
                        value = false;
                    }
                    return value;
                });
            $(this).parents('.col-12').siblings().find('input, textarea').val('');
            $(this).parents('.col-12').siblings().find('select').children(':first').prop('selected', true);
        });
    },
    bowedSelect: function () {
        $('.bowed-section').on('click', function () {
            if ($(this).has('input[name=bowedEnds]')) {
                $(this).siblings('.bowed-section').removeClass('selected');
                $(this).addClass('selected');
            }
        })
    },
    concreteSelect: function () {
        $('input[name="concretePerimeter"]').on('click', function () {
            var $container = $(this).parents().siblings('.decking-input-container');
            if ($(this).val() === 'No') {
                $container.removeClass('d-none').addClass('required');
                $container
                    .children('input')
                    .prop('disabled', false)
                    .prop('required', true);
            } else {
                $container.addClass('d-none').removeClass('required');
                $container
                    .children('input')
                    .prop('disabled', true)
                    .prop('required', false);
            }

        })
    },
    openForm: function () {
        $('.btn-red').on('click', function () {
            $('body').trigger('formOpener:open');
        })
        $('.js-custom-form *').filter(':input').each(function () {
            var $this = $(this);

            if ($this.attr('name') in window.customerData) {
                var customerData = window.customerData[$this.attr('name')];

                if (customerData && typeof customerData === 'object') {
                    $this.val(customerData.value);

                    if (!customerData.valid) {
                        $this.addClass('is-invalid');
                        $('body').trigger('form:showErrorMessage', { invalidInput: $this, errorMessage: customerData.errorMessage });
                    } else {
                        $this.removeClass('is-invalid');
                    }
                } else {
                    $this.val(customerData);
                }
            }
        });
    },
    toggleHiddenInputDiagrams: function () {
        $('#contour-radio-rectangle-with-standard-hopper').on('click', function () {
        	enableInputDiagrams($('#contour-image-rectangle-with-standard-hopper'));
            disableInputDiagrams($('#contour-image-rectangle-with-safety-ledge'));
            disableInputDiagrams($('#contour-image-rectangle-with-flat-bottom-and-cove'));
            disableInputDiagrams($('#contour-image-rectangle-with-sport'));
        });

        $('#contour-radio-rectangle-with-safety-ledge').on('click', function () {
        	disableInputDiagrams($('#contour-image-rectangle-with-standard-hopper'));
        	enableInputDiagrams($('#contour-image-rectangle-with-safety-ledge'));
            disableInputDiagrams($('#contour-image-rectangle-with-flat-bottom-and-cove'));
            disableInputDiagrams($('#contour-image-rectangle-with-sport'));
        });

        $('#contour-radio-rectangle-with-flat-bottom-and-cove').on('click', function () {
        	disableInputDiagrams($('#contour-image-rectangle-with-standard-hopper'));
        	disableInputDiagrams($('#contour-image-rectangle-with-safety-ledge'));
        	enableInputDiagrams($('#contour-image-rectangle-with-flat-bottom-and-cove'));
            disableInputDiagrams($('#contour-image-rectangle-with-sport'));
        });

        $('#contour-radio-rectangle-with-sport').on('click', function () {
        	disableInputDiagrams($('#contour-image-rectangle-with-standard-hopper'));
        	disableInputDiagrams($('#contour-image-rectangle-with-safety-ledge'));
        	disableInputDiagrams($('#contour-image-rectangle-with-flat-bottom-and-cove'));
        	enableInputDiagrams($('#contour-image-rectangle-with-sport'));
        });

        $('#contour-radio-oval-with-flat-bottom-and-cove').on('click', function () {
        	enableInputDiagrams($('#contour-image-oval-with-flat-bottom-and-cove'));
            disableInputDiagrams($('#contour-image-oval-with-safety-ledge'));
            disableInputDiagrams($('#contour-image-oval-with-oval-hopper'));
        });

        $('#contour-radio-oval-with-safety-ledge').on('click', function () {
        	disableInputDiagrams($('#contour-image-oval-with-flat-bottom-and-cove'));
        	enableInputDiagrams($('#contour-image-oval-with-safety-ledge'));
            disableInputDiagrams($('#contour-image-oval-with-oval-hopper'));
        });

        $('#contour-radio-oval-with-oval-hopper').on('click', function () {
        	disableInputDiagrams($('#contour-image-oval-with-flat-bottom-and-cove'));
        	disableInputDiagrams($('#contour-image-oval-with-safety-ledge'));
        	enableInputDiagrams($('#contour-image-oval-with-oval-hopper'));
        });
    }
};
