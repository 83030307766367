var processInclude = require('base/util');

$(document).ready(function () {
    $('#prepaid-yes').click(function () {
        $(".transaction-id").show(200);
        $('#prepaid-yes').attr("checked", true);
        $('#prepaid-no').attr("checked", false);

    });
    $('#prepaid-no').click(function () {
        $(".transaction-id").hide(200);
        $('#prepaid-yes').attr("checked", false);
        $('#prepaid-no').attr("checked", true);
    });

    $('#store-associate').val($('input[name=storeAssociate]').val());
    $('#storeAssociate').prop('disabled', true);

    $('#store-id').val($('input[name=store-ID]').val());
    $('#store-ID').prop('disabled', true);

    $('#email-confirmation').on("blur", function () {
        var emailConfirm = $('#email-confirmation').val();
        var email = $('#email').val();
        if (email != emailConfirm) {
            $("#email-confirmation-error").html("Your emails do not match!");
            $('#email-confirmation').addClass("is-invalid");
            $("#request-service-submit").attr("disabled", "disabled")
        } else {
            $("#email-confirmation-error").html("");
            $('#email-confirmation').removeClass("is-invalid");
        }
    })

    $('#work-description').on("blur", function () {
        var workDesc = $('#work-description').val();
        var storeFunc = $('#store-ID').val();
        if (storeFunc != 'null') {
            if (!workDesc) {
                $("#work-description-error").html("This field is required!");
                $('#work-description').addClass("is-invalid");
                $("#request-service-submit").attr("disabled", "disabled")
            } else {
                $("#work-description-error").html("");
                $('#work-description').removeClass("is-invalid");
            }
        }
    })

    $('#request-service-submit').on("click", function () {
        var workDesc = $('#work-description').val();
        var storeFunc = $('#store-ID').val();
        if (storeFunc != 'null') {
            if (!workDesc) {
                $("#work-description-error").html("This field is required!");
                $('#work-description').addClass("is-invalid");
                $("#request-service-submit").attr("disabled", "disabled");
            }
        }
    })

    processInclude(require('./customForm/customPoolForm'));
});
